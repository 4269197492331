<template>
    <div id="createnewsite" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-homemenu></app-homemenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <div class="kt-subheader__main">
                                <h3 class="kt-subheader__title">{{ $t("site_createNewSite") }}</h3>
                                <span class="kt-subheader__separator kt-subheader__separator--v"></span>
                                <span class="kt-subheader__desc">{{ $t("site_createNewSiteSubTitle") }}</span>
                            </div>
                        </div>
                        <!-- end:: Content Head -->

                        <!-- begin:: Content -->
                        <div class="kt-content kt-grid__item kt-grid__item--fluid">
                            <!-- begin:: Content -->
                            <div class="row">
                                <div class="col-md-12">
                                    <!--begin::Portlet-->
                                    <div class="kt-portlet">
                                        <div class="kt-portlet__head">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24" />
                                                            <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                                                            <path d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z" fill="#000000" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("site_createNewSite") }}
                                                </h3>
                                            </div>
                                        </div>
                                        <!--begin::Form-->
                                        <form class="kt-form kt-form--label-right" data-cy="NewSiteForm" autocomplete="off">
                                            <div class="kt-portlet__body">
                                                <div class="kt-section">
                                                    <h3 class="kt-section__title">1. {{ $t("site_siteInfo") }}</h3>
                                                    <div class="kt-section__body">
                                                        <div class="form-group row validated">
                                                            <div class="col-lg-6">
                                                                <label>{{ $t("site_siteName") }} *</label>
                                                                <div class="input-group">
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"><i class="la la-pencil"></i></span>
                                                                    </div>
                                                                    <input id="newSiteNameInput" type="text" maxlength="80" class="form-control" :placeholder="$t('site_inputInfoSiteName')" v-model="newSiteNameInputVal" />
                                                                </div>
                                                                <div v-if="!$v.newSiteNameInputVal.required" class="invalid-feedback">
                                                                    {{ $t("error_fieldIsRequired") }}
                                                                </div>
                                                                <div v-else-if="!$v.newSiteNameInputVal.minLen" class="invalid-feedback">
                                                                    {{ $t("error_minLengthMsg", [$v.newSiteNameInputVal.$params.minLen.min]) }}
                                                                </div>
                                                                <span class="form-text text-muted">{{ $t("site_detailInfoSiteName") }}</span>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <label >{{ $t("site_siteType") }} *</label>
                                                                <div class="site-type">
                                                                    <div style="width: 100%" id="newSiteType" class="form-site-type form-column form-column-field">
                                                                        <input id="newSiteTypeInput" type="text" maxlength="80" @click="onShowListSiteType()" readonly="readonly" class="select-dropdown form-control cursorPointer" :placeholder="$t('site_detailInfoSiteType')" v-model="newSiteTypeInputVal"/>
                                                                        <i class="fa fa-angle-down icon-down"></i>
                                                                        <div class="list-site-type">
                                                                            <ul>
                                                                                <li v-for="siteType in this.siteTypes" :key="siteType.id" @click="onSelectedSiteType(siteType)" :class="newSiteTypeSelectVal.id === siteType.id && 'site-type-selected'">
                                                                                    <span v-if="siteType.isDefault">{{$t(siteType.name)}}</span>
                                                                                    <div v-else style="display: flex; justify-content: space-between; align-items: center;">
                                                                                        <div>{{siteType.name}}</div>
                                                                                        <div>
                                                                                            <button type="button" @click="onEditSiteType(siteType)" class="btn btn-outline-brand btn-sm btn-icon border-0 mr-2" style="height: 1.9rem; width: 1.9rem"><i class="la la-pencil"></i></button>
                                                                                            <button type="button" @click="onDeleteSiteType(siteType.id)" class="btn btn-outline-danger btn-sm btn-icon border-0" style="height: 1.9rem; width: 1.9rem"><i class="la la-trash"></i></button>    
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div @click="onShowAddNewSiteType()" class="btn btn-brand btn-bold btn-md btn-icon-h kt-margin-l-10 cursorPointer">
                                                                        <span style="font-size: 0.9rem;">
                                                                            <i class="flaticon2-plus kt-margin-r-5"></i>
                                                                            {{ $t("site_createSiteType") }}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div v-if="!$v.newSiteTypeSelectVal.required" class="invalid-feedback">
                                                                    {{ $t("error_fieldIsRequired") }}
                                                                </div>
                                                                <span class="form-text text-muted">{{ $t("site_detailInfoSiteType") }}</span>
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <div class="col-lg-12">
                                                                <label for="siteGatewayType">{{ $t("gtw_gatewayType") }} *</label>

                                                                <div class="kt-section__body">
                                                                    <div role="alert" class="alert alert-secondary">
                                                                        <div class="alert-icon">
                                                                            <i class="flaticon2-information kt-font-brand"></i>
                                                                        </div>
                                                                        <div class="alert-text">
                                                                            <ul class="marginB0">
                                                                                <li><strong class="fontMonospace">1 . </strong>
                                                                                    {{ $t("site_BLEGatewaysTypeHelpL1") }}
                                                                                </li>
                                                                                <li><strong class="fontMonospace">2 . </strong>
                                                                                    {{ $t("site_BLEGatewaysTypeHelpL2") }}
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div class="form-group form-group-last validated">
                                                                        <div class="kt-radio-list">
                                                                            <label v-for="BLEgatewaysType in BLEGatewaysTypeList" :key="BLEgatewaysType" class="kt-radio kt-radio--bold kt-radio--brand">
                                                                                <input type="radio" :id="'gateWayRadio_' + BLEgatewaysType" name="BLEgatewaysType" :value="BLEgatewaysType" v-model="newBLEGatewaysType" checked="checked" /> {{ $t(BLEgatewaysType) }} <br />
                                                                                <span></span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                        
                                                        <div class="form-group row">
                                                            <div class="col-lg-12">
                                                                <label for="siteDescription">{{ $t("site_siteDescription") }}</label>
                                                                <textarea maxlength="1000" id="newSiteDescriptionTextArea" class="form-control" rows="3" v-model="newSiteDescriptionTextAreaVal"></textarea>
                                                                <span class="form-text text-muted">{{ $t("site_detailInfoSiteDescription") }}</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit"></div>

                                                    <h3 class="kt-section__title">2. {{ $t("site_siteAddressDetails") }}</h3>
                                                    <div class="kt-section__body">
                                                        <div class="form-group row">
                                                            <div class="col-lg-6">
                                                                <label>{{ $t("common_address") }}</label>
                                                                <div class="input-group">
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"><i class="la la-envelope"></i></span>
                                                                    </div>
                                                                    <input id="newSiteAddressInput" maxlength="80" type="text" class="form-control" :placeholder="$t('site_inputInfoSiteAddress')" v-model="newSiteAddressInputVal" />
                                                                </div>
                                                                <span class="form-text text-muted">{{ $t("site_detailInfoSiteAddress") }}</span>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <label >{{ $t("common_postCode") }}</label>
                                                                <div class="input-group">
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"><i class="la la-bookmark"></i></span>
                                                                    </div>
                                                                    <input id="newSitePostcodeInput" maxlength="80" type="text" class="form-control" :placeholder="$t('site_inputInfoSitePostcode')" v-model="newSitePostcodeInputVal" />
                                                                </div>
                                                                <span class="form-text text-muted">{{ $t("site_detailInfoSitePostcode") }}</span>
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <div class="col-lg-6">
                                                                <label>{{ $t("common_city") }}</label>
                                                                <div class="input-group">
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"><i class="la la-map-marker"></i></span>
                                                                    </div>
                                                                    <input id="newSiteCityInput" maxlength="80" type="text" class="form-control" :placeholder="$t('site_inputInfoSiteCity')" v-model="newSiteCityInputVal" />
                                                                </div>
                                                                <span class="form-text text-muted">{{ $t("site_detailInfoSiteCity") }}</span>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <label >{{ $t("common_country") }}</label>
                                                                <select id="newSiteCountrySelect" class="form-control kt-select2">
                                                                    <!-- To display placeholder -->
                                                                    <option></option>
                                                                </select>
                                                                <span class="form-text text-muted">{{ $t("site_detailInfoSiteCountry") }}</span>
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <div class="col-lg-6" v-show='newSiteCountrySelectVal != "US"'>
                                                                <label>{{ $t("common_state") }}</label>
                                                                <div class="input-group">
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"><i class="la la-map"></i></span>
                                                                    </div>
                                                                    <input id="newSiteStateInput" maxlength="80" type="text" class="form-control" :placeholder="$t('site_inputInfoSiteState')" v-model="newSiteStateInputVal" />
                                                                </div>
                                                                <span class="form-text text-muted">{{ $t("site_detailInfoSiteState") }}</span>
                                                            </div>
                                                            <div class="col-lg-6" v-show='newSiteCountrySelectVal == "US"'>
                                                                <label>{{ $t("common_state") }}</label>
                                                                <select id="usaStateSelect" class="form-control kt-select2">
                                                                    <!-- To display placeholder -->
                                                                    <option></option>
                                                                </select>
                                                                <span class="form-text text-muted">{{ $t("site_detailInfoSiteState") }}</span>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <label >{{ $t("common_timezone") }}</label>
                                                                <select id="newSiteTimezoneSelect" class="form-control kt-select2">
                                                                    <!-- To display placeholder -->
                                                                    <option></option>
                                                                </select>
                                                                <span class="form-text text-muted">{{ $t("site_detailInfoSiteTimezone") }}</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit"></div>

                                                    <h3 class="kt-section__title">3. {{ $t("site_calibrationMode") }}</h3>
                                                    <div class="kt-section__body">
                                                        <div class="form-group row form-group-last form-group-marginless">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-6">
                                                                        <label class="kt-option cursorPointer" :class="calibrationMode === 'GATEWAYS_AND_BEACONS' ? 'tagBorderSelected' : 'tagBorderNotSelected'">
                                                                            <span class="kt-option__control">
                                                                                <span class="kt-radio kt-radio--bold kt-radio--brand kt-radio--check-bold" checked="">
                                                                                    <input v-model="calibrationMode" type="radio" id="newSite_GATEWAYS_AND_BEACONS_RadioButton" name="GATEWAYS_AND_BEACONS_radio" value="GATEWAYS_AND_BEACONS" :checked="calibrationMode == 'GATEWAYS_AND_BEACONS'" />
                                                                                    <span></span>
                                                                                </span>
                                                                            </span>
                                                                            <span class="kt-option__label">
                                                                                <span class="kt-option__head">
                                                                                    <span class="kt-option__title" :class="calibrationMode === 'GATEWAYS_AND_BEACONS' ? 'tagLabelSelected' : 'tagLabelNotSelected'">{{ $t("site_calibrationModeWithGatewaysAndBeaconsTitle") }}</span>
                                                                                </span>
                                                                                <span class="kt-option__body">
                                                                                    {{ $t("site_calibrationModeWithGatewaysAndBeaconsDesc") }}
                                                                                </span>
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-6">
                                                                        <label class="kt-option cursorPointer" :class="calibrationMode === 'GATEWAYS_ONLY' ? 'tagBorderSelected' : 'tagBorderNotSelected'">
                                                                            <span class="kt-option__control">
                                                                                <span class="kt-radio kt-radio--bold kt-radio--brand kt-radio--check-bold" checked="">
                                                                                    <input v-model="calibrationMode" type="radio" id="newSite_GATEWAYS_ONLY_RadioButton" name="GATEWAYS_ONLY_radio" value="GATEWAYS_ONLY" :checked="calibrationMode == 'GATEWAYS_ONLY'" />
                                                                                    <span></span>
                                                                                </span>
                                                                            </span>
                                                                            <span class="kt-option__label">
                                                                                <span class="kt-option__head">
                                                                                    <span class="kt-option__title" :class="calibrationMode === 'GATEWAYS_ONLY' ? 'tagLabelSelected' : 'tagLabelNotSelected'">{{ $t("site_calibrationModeWithGatewaysOnlyTitle") }}</span>
                                                                                </span>
                                                                                <span class="kt-option__body">
                                                                                    {{ $t("site_calibrationModeWithGatewaysOnlyDesc") }}
                                                                                </span>
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit"></div>

                                                    <h3 class="kt-section__title">4. {{ $t("site_walkwayMode") }}</h3>
                                                    <div class="kt-section__body">
                                                        <div class="form-group row form-group-last form-group-marginless">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-6">
                                                                        <label class="kt-option cursorPointer" :class="walkwayMode === 'WITHOUT_WALKWAY' ? 'walkwayBorderSelected' : 'walkwayBorderNotSelected'">
                                                                            <span class="kt-option__control">
                                                                                <span class="kt-radio kt-radio--bold kt-radio--brand kt-radio--check-bold" checked="">
                                                                                    <input v-model="walkwayMode" type="radio" id="newSite_WITHOUT_WALKWAY_RadioButton" name="WITHOUT_WALKWAY_radio" value="WITHOUT_WALKWAY" :checked="walkwayMode == 'WITHOUT_WALKWAY'" />
                                                                                    <span></span>
                                                                                </span>
                                                                            </span>
                                                                            <span class="kt-option__label">
                                                                                <span class="kt-option__head">
                                                                                    <span class="kt-option__title" :class="walkwayMode === 'WITHOUT_WALKWAY' ? 'walkwayLabelSelected' : 'walkwayLabelNotSelected'">{{ $t("site_walkwayModeWithoutWalkwayTitle") }}</span>
                                                                                </span>
                                                                                <span class="kt-option__body">
                                                                                    {{ $t("site_walkwayModeWithoutWalkwayDesc") }}
                                                                                </span>
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-6">
                                                                        <label class="kt-option cursorPointer" :class="walkwayMode === 'WITH_WALKWAY' ? 'walkwayBorderSelected' : 'walkwayBorderNotSelected'">
                                                                            <span class="kt-option__control">
                                                                                <span class="kt-radio kt-radio--bold kt-radio--brand kt-radio--check-bold" checked="">
                                                                                    <input v-model="walkwayMode" type="radio" id="newSite_WITH_WALKWAY_RadioButton" name="WITH_WALKWAY_radio" value="WITH_WALKWAY" :checked="walkwayMode == 'WITH_WALKWAY'" />
                                                                                    <span></span>
                                                                                </span>
                                                                            </span>
                                                                            <span class="kt-option__label">
                                                                                <span class="kt-option__head">
                                                                                    <span class="kt-option__title" :class="walkwayMode === 'WITH_WALKWAY' ? 'walkwayLabelSelected' : 'walkwayLabelNotSelected'">{{ $t("site_walkwayModeWithWalkwayTitle") }}</span>
                                                                                </span>
                                                                                <span class="kt-option__body">
                                                                                    {{ $t("site_walkwayModeWithWalkwayDesc") }}
                                                                                </span>
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> -->

                                                    <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit"></div>

                                                    <h3 class="kt-section__title">3. {{ $t("site_siteImage") }}</h3>
                                                    <div class="kt-section__body">
                                                        <div role="alert" class="alert alert-secondary">
                                                            <div class="alert-icon">
                                                                <i class="flaticon-photo-camera kt-font-brand"></i>
                                                            </div>
                                                            <div class="alert-text">
                                                                <span>{{ $t("site_siteImageHelp") }}</span>
                                                            </div>
                                                        </div>
                                                        <div class="form-group form-group-last row">
                                                            <div class="col-lg-6">
                                                                <label>{{ $t("common_fileBrowser") }}</label>
                                                                <div class="custom-file">
                                                                    <input type="file" @change="handleFileSelected" ref="imageSiteFile" :lang="this.$i18n.locale" class="custom-file-input cursorPointer" accept="image/gif, image/jpeg, image/png" id="selectSiteImageInputFile" />
                                                                    <label class="custom-file-label textLeft" id="selectSiteImageInputLabel" for="selectSiteImageInputFile">{{ $t("common_chooseFile") }}</label>
                                                                    <span class="form-text text-muted">{{ $t("site_selectImageHelpText") }}</span>
                                                                </div>
                                                            </div>
                                                            <div v-show="isImageLoaded" class="col-lg-6">
                                                                <div class="imagePreview" v-bind:style="{ backgroundImage: 'url(' + this.newSiteImage + ')' }"></div>
                                                                <div class="kt-margin-b-10 kt-margin-t-10">{{ newSiteImageName }}</div>
                                                                <div>
                                                                    <button id="newSiteRemoveImageButton" type="button" @click="onRemoveImage" class="btn btn-outline-brand">{{ $t("common_remove") }}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit"></div>

                                                        <h3 class="kt-section__title">4. {{ $t("site_IQMConfiguration") }}</h3>

                                                        <div class="kt-section__body">
                                                            <!--begin:HelpIQM-->
                                                            <div role="alert" class="alert alert-secondary">
                                                                <div class="alert-icon">
                                                                    <i class="flaticon2-list-1 kt-font-brand"></i>
                                                                </div>
                                                                <div class="alert-text">
                                                                    <ul class="marginB0">
                                                                        <li><strong class="fontMonospace">1 . </strong>
                                                                            {{ $t("site_IQMHelpL1_1") }}
                                                                            <a class="kt-link" href="#" data-placement="right" data-toggle="kt-popover" data-html="true" title :data-content="$t('site_IQMServerVersionContent')" :data-original-title="$t('site_IQMServerVersionTitle')">
                                                                                {{ $t("site_IQMHelpL1_2") }}
                                                                            </a>
                                                                            {{ $t("site_IQMHelpL1_3") }}
                                                                        </li>
                                                                        <li><strong class="fontMonospace">2 . </strong>
                                                                            {{ $t("site_IQMHelpL2_1") }}
                                                                            <a class="kt-link" href="#" data-placement="right" data-toggle="kt-popover" data-html="true" title :data-content="$t('site_IQMServerAdminServicesContent')" :data-original-title="$t('site_IQMServerAdminServicesTitle')">
                                                                                {{ $t("site_IQMHelpL2_2") }}
                                                                            </a>
                                                                            {{ $t("site_IQMHelpL2_3") }}
                                                                        </li>
                                                                        <li><strong class="fontMonospace">3 . </strong>
                                                                            {{ $t("site_IQMHelpL3") }}
                                                                        </li>
                                                                        <li><strong class="fontMonospace">4 . </strong>
                                                                            {{ $t("site_IQMHelpL4_1") }}
                                                                            <a class="kt-link" href="#" data-placement="right" data-toggle="kt-popover" data-html="true" title :data-content="$t('site_IQMApiEventFlowsContent')" :data-original-title="$t('site_IQMApiEventFlowsTitle')">
                                                                                {{ $t("site_IQMHelpL4_2") }}
                                                                            </a>
                                                                            {{ $t("site_IQMHelpL4_3") }}
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <!--end:HelpIQM-->
                                                            <div class="form-group row validated">
                                                                <div class="col-lg-6">
                                                                    <label>{{ $t("site_IQMUrl") }}</label>
                                                                    <div class="input-group">
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"><i class="la la-server"></i></span>
                                                                        </div>
                                                                        <input id="newSiteIQMServerUrl" maxlength="255" type="url" class="form-control" :placeholder="$t('site_IQMUrlPlaceholder')" v-model="IQMServerUrl" :disabled="IQMServerIsVerify" />
                                                                    </div>
                                                                    <div v-if="!$v.IQMServerUrl.required" class="invalid-feedback">
                                                                        {{ $t("error_fieldIsRequired") }}
                                                                    </div>
                                                                    <div class="invalid-feedback" v-else-if="!$v.IQMServerUrl.url">
                                                                        {{ $t("error_invalidUrlMsg") }}
                                                                    </div>
                                                                    <span class="form-text text-muted">{{ $t("site_IQMUrlDetail") }}</span>
                                                                </div>
                                                                <div class="col-lg-6">
                                                                    <label >{{ $t("site_IQMPort") }}</label>
                                                                    <div class="input-group">
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"><i class="la la-support"></i></span>
                                                                        </div>
                                                                        <input id="newSiteIQMServerPort" min="0" type="number" class="form-control" pattern="[0-9]{1,5}" :placeholder="$t('site_IQMPortPlaceholder')" v-model="IQMServerPort" :disabled="IQMServerIsVerify" />
                                                                    </div>
                                                                    <div v-if="!$v.IQMServerPort.required" class="invalid-feedback">
                                                                        {{ $t("error_fieldIsRequired") }}
                                                                    </div>
                                                                    <div class="invalid-feedback" v-else-if="!$v.IQMServerPort.integer || !$v.IQMServerPort.between">
                                                                        {{ $t("error_invalidAddressPort") }}
                                                                    </div>
                                                                    <span class="form-text text-muted">{{ $t("site_IQMPortDetail") }}</span>
                                                                </div>
                                                            </div>
                                                            <div class="form-group row validated">
                                                                <div class="col-lg-6">
                                                                    <label>{{ $t("site_IQMUserName") }}</label>
                                                                    <div class="input-group">
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"><i class="la la-user"></i></span>
                                                                        </div>
                                                                        <input id="newSiteIQMServerUserName" maxlength="80" type="text" autocomplete="new-username" class="form-control" :placeholder="$t('site_IQMUserNamePlaceholder')" value="" v-model="IQMServerUsername" :disabled="IQMServerIsVerify" />
                                                                    </div>
                                                                    <div v-if="!$v.IQMServerUsername.required" class="invalid-feedback">
                                                                        {{ $t("error_fieldIsRequired") }}
                                                                    </div>
                                                                    <span class="form-text text-muted">{{ $t("site_IQMUsernameDetail") }}</span>
                                                                </div>
                                                                <div class="col-lg-6">
                                                                    <label>{{ $t("site_IQMPassword") }}</label>
                                                                    <div class="input-group">
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"><i class="la la-key"></i></span>
                                                                        </div>
                                                                        <input id="newSiteIQMServerPassword" maxlength="80" type="password" autocomplete="new-password" class="form-control" :placeholder="$t('site_IQMPasswordPlaceholder')" value="" v-model="IQMServerPassword" :disabled="IQMServerIsVerify" />
                                                                    </div>
                                                                    <div v-if="!$v.IQMServerPassword.required" class="invalid-feedback">
                                                                        {{ $t("error_fieldIsRequired") }}
                                                                    </div>
                                                                    <span class="form-text text-muted">{{ $t("site_IQMPasswordDetail") }}</span>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-lg-12">
                                                                    <button v-if="IQMServerIsVerify" id="newSiteResetIQMServerFormButton" @click="onResetIQMServerButton" type="button" :disabled="!IQMFormIsCorrect" class="btn btn-danger kt-margin-r-5">
                                                                        <i class="fa fa-undo kt-margin-t-2 kt-margin-r-5"></i>{{ $t("site_IQMResetIQMForm") }}
                                                                    </button>
                                                                    <button v-else id="newSiteTestIQMServerButton" @click="onTestIQMServerButton" type="button" :disabled="!IQMFormIsCorrect" class="btn btn-brand kt-margin-r-5">
                                                                        <i class="fa fa-check-circle kt-margin-t-2 kt-margin-r-5"></i>{{ $t("site_IQMTestConnection") }}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div v-if="isSuperAdmin()">
                                                        <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit"></div>

                                                        <h3 class="kt-section__title">5. {{ $t("site_siteEngine") }}</h3>
                                                        <div class="kt-section__body">
                                                            <div class="form-group form-group-last validated">
                                                                <p class="kt-margin-b-30">{{ $t("site_siteEngineSelection") }}</p>
                                                                <div class="kt-radio-list">
                                                                    <label v-for="engineMode in engineModeList" :key="engineMode" class="kt-radio kt-radio--bold kt-radio--brand">
                                                                        <input type="radio" :id="'engineRadio_' + engineMode" name="engine" :value="engineMode" v-model="newSiteEngineMode" checked="checked" /> {{ $t(engineMode) }} <br />
                                                                        <span></span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="kt-portlet__foot">
                                                <div class="kt-form__actions">
                                                    <div class="row">
                                                        <div class="col-lg-6 kt-align-right"></div>
                                                        <div class="col-lg-6 kt-align-right">
                                                            <label class="kt-checkbox kt-margin-r-20">
                                                                <input v-model="createAnother" type="checkbox" /> {{ $t("common_createAnother") }}
                                                                <span></span>
                                                            </label>
                                                            <button id="newSiteCreateButton" @click="onCreateButton" type="button" :disabled="!enableCreateButton" class="btn btn-brand kt-margin-r-5">
                                                                {{ $t("common_add") }}
                                                            </button>
                                                            <button id="newSiteCancelButton" @click="onCancelButton" type="button" class="btn btn-secondary">{{ $t("common_cancel") }}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <!--end::Form-->
                                    </div>
                                    <!--end::Portlet-->
                                </div>
                            </div>
                            <!-- end:: Content -->
                        </div>
                        <!-- end:: Content -->
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->

                    <!-- begin:: Modal to confirm leave page -->
                    <app-confirmleavepagemodal ref="confirmleavepagemodalref"></app-confirmleavepagemodal>
                    <!-- end::Modal -->

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { required, minLength, url, integer, between, requiredIf } from "vuelidate/lib/validators";
import i18n from "../../i18n";
import commonVueHelper from "../../helpers/commonVueHelper";

import _ct from "countries-and-timezones";
const _siteTypes = require("../../constants/siteTypes");
const _orderBy = require("lodash.orderby");
const _usaStates = require('usa-states').UsaStates;

export default {
    data() {
        return {
            countries: _ct.getAllCountries(),
            timezones: _ct.getAllTimezones(),
            usaStates: new _usaStates().states,
            newSiteCountrySelectVal: "",
            newSiteTimezoneSelectVal: _ct.getTimezonesForCountry("US")[0].name,
            newSiteTypeSelectVal: "",
            newSiteTypeInputVal: "",
            newSiteNameInputVal: "",
            newSiteDescriptionTextAreaVal: "",
            newSiteAddressInputVal: "",
            newSitePostcodeInputVal: "",
            newSiteCityInputVal: "",
            newSiteStateInputVal: "",
            newSiteImage: "",
            newSiteImageName: "",
            IQMServerUrl: null,
            IQMServerPort: null,
            IQMServerUsername: null,
            IQMServerPassword: null,
            imageFile: null,
            isImageLoaded: false,
            createAnother: false,
            calibrationMode: "GATEWAYS_AND_BEACONS",
            walkwayMode: "WITHOUT_WALKWAY",
            engineModeList: ["HAN", "NEURAL_NETWORK", "ALE_ENGINE"],
            newSiteEngineMode: "ALE_ENGINE",
            BLEGatewaysTypeList: ["OMNIACCESS_STELLAR", "OMNIACCESS_OEM"],
            newBLEGatewaysType: "OMNIACCESS_STELLAR",
            isFirstInitSiteTypeSelect2: true,
            isHandleEditOrDelete: false,
        };
    },
    created: function() {
        console.log("Component(NewSite)::created() - called");
        this.createAnother = this.createAnotherSite;
        this.setCreateAnotherSite(false);
        this.getAllSiteType();
    },
    mounted: function() {
        console.log("Component(NewSite)::mounted() - Init metronic layout");
        KTLayout.init();
        // this.initSiteTypeSelect2();
        this.initSiteCountrySelect2();
        this.initSiteTimezoneSelect2();
        this.initUsaStatesSelect2();

        // Force to set US country bu default
        if ($("#newSiteCountrySelect").data("select2")) {
            $("#newSiteCountrySelect").val("US");
            $("#newSiteCountrySelect").trigger("change");
        }
    },
    destroyed: function() {
        console.log("Component(NewSite)::destroyed() - called");
        this.resetSiteTypesState();
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
        newSiteNameInputVal: {
            required,
            minLen: minLength(2)
        },
        newSiteTypeSelectVal: {
            required
        },
        newSiteTimezoneSelectVal: {
            required
        },
        IQMServerUrl: {
            url,
            required: requiredIf(function() {
                return this.IQMServerUsername || this.IQMServerPassword || this.IQMServerPort;
            })
        },
        IQMServerPort: {
            integer,
            between: between(0, 65536),
            required: requiredIf(function() {
                return this.IQMServerUsername || this.IQMServerPassword || this.IQMServerUrl;
            })
        },
        IQMServerUsername: {
            required: requiredIf(function() {
                return this.IQMServerUrl || this.IQMServerPassword || this.IQMServerPort;
            })
        },
        IQMServerPassword: {
            required: requiredIf(function() {
                return this.IQMServerUrl || this.IQMServerUsername || this.IQMServerPort;
            })
        }
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        newSiteCountrySelectVal: function(val) {
            console.log("Component(NewSite)::watch(newSiteCountrySelectVal) - called with ", val);
            // When a new country is selected, update the selected timezone value with the corresponding country timezone
            if (val) {
                let countryTimezones = _ct.getTimezonesForCountry(val);
                if (countryTimezones) {
                    this.newSiteTimezoneSelectVal = countryTimezones[0].name;
                    $("#newSiteTimezoneSelect").val(this.newSiteTimezoneSelectVal);
                    $("#newSiteTimezoneSelect").trigger("change");
                }
            }
        },

        user: function(user) {
            console.log("Component(NewSite)::watch(user) - called with ", user);
            if (user) {
                // In case of language change
                commonVueHelper.resetImageInputLabel(this.isImageLoaded);
                // this.updateSiteTypeSelect2(user.preferredLanguage);

                this.newSiteTypeInputVal = this.newSiteTypeSelectVal ?
                    this.newSiteTypeSelectVal.isDefault ?
                        i18n.t(this.newSiteTypeSelectVal.name) :
                        this.newSiteTypeSelectVal.name
                    : "";
            }
        },
        siteTypes: function(siteTypes) {
            console.log("Component(NewSite)::watch(siteTypes) - called with ", siteTypes);
            let newSiteType = siteTypes.find(siteType => {return siteType.isNewCreated});

            if (!newSiteType) {
                newSiteType = siteTypes.find(siteType => { return this.newSiteTypeSelectVal && siteType.id ===  this.newSiteTypeSelectVal.id });
            }

            if (newSiteType) {
                this.newSiteTypeSelectVal = newSiteType;

                if (newSiteType.isDefault) {
                    this.newSiteTypeInputVal = i18n.t(newSiteType.name);
                } else {
                    this.newSiteTypeInputVal = newSiteType.name;
                }
            }     
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["createAnotherSite", "user", "IQMServerIsVerify", "siteTypes"]),

        orderedCountries: function() {
            return _orderBy(this.countries, "name");
        },

        orderedTimezones: function() {
            return _orderBy(this.timezones, "name");
        },

        orderedUsaStates: function() {
            return _orderBy(this.usaStates, "name");
        },

        IQMFormIsCorrect: function() {
            return  this.IQMServerUrl && !this.$v.IQMServerUrl.$invalid && !this.$v.IQMServerPort.$invalid && !this.$v.IQMServerUsername.$invalid && !this.$v.IQMServerPassword.$invalid && !this.$v.IQMServerPort.$invalid;
        },

        enableCreateButton: function() {
            if (!this.$v.$invalid) {
                // Now verify if IQM Server is configured or not
                if (!this.IQMServerUrl && !this.IQMServerPort && !this.IQMServerUsername && !this.IQMServerPassword) {
                    return true;
                } else if (this.IQMFormIsCorrect && this.IQMServerIsVerify) {
                    return true;
                }
            }
            return false;
        }
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions([
            "createUserSite",
            "setCreateAnotherSite",
            "invalidateIQMServer",
            "checkIQMServer",
            "getAllSiteType",
            "showSiteTypeModal",
            "deleteSiteTypeById",
            "resetSiteTypesState"
        ]),

        onShowAddNewSiteType() {
            console.log("Component(NewSite)::onShowAddNewSiteType() - called");
            this.showSiteTypeModal({type: "CREATE"});
        },

        onSelectedSiteType(siteType) {
            // setTimeout( () => {
            if(!this.isHandleEditOrDelete) {
                this.newSiteTypeSelectVal = siteType;
                if (siteType.isDefault) {
                    this.newSiteTypeInputVal = i18n.t(siteType.name);
                } else {
                    this.newSiteTypeInputVal = siteType.name;
                }
            }
            // }, 50);
            $(".list-site-type").hide();
        },

        onShowListSiteType() {
            $("#newSiteTypeInput").on('keydown keypress', function (e) {
                e.preventDefault();
            });
            if ($(".list-site-type").css('display') === 'none') {
                $(".list-site-type").show();
                this.registerEventSiteType();
            } else {
                $(".list-site-type").hide();
            }
            if($(".site-type-selected")[0]) {
                $(".list-site-type").animate({
                    scrollTop: $(".site-type-selected")[0].offsetTop - 150
                }, 0);
            }
        },

        registerEventSiteType() {
            if ($(".list-site-type").css('display') === 'block') {
                $("body").click(function (e) {
                    if (
                        (!$(".list-site-type").is(e.target) && $(".list-site-type").has(e.target).length === 0) &&
                        (!$("#newSiteTypeInput").is(e.target) && $("#newSiteTypeInput").has(e.target).length === 0)
                    ) {
                        $(".list-site-type").hide();
                    }
                });
            }
        },

        onEditSiteType(siteType) {
            this.isHandleEditOrDelete = true;
            this.showSiteTypeModal({type: "UPDATE", data: siteType });
            setTimeout(() => {
                this.isHandleEditOrDelete = false;
            }, 100);
        },

        onDeleteSiteType(siteTypeId) {
            this.isHandleEditOrDelete = true;
            this.deleteSiteTypeById({ siteTypeId: siteTypeId });
            if (siteTypeId === this.newSiteTypeSelectVal.id) {
                this.newSiteTypeSelectVal = "";
                this.newSiteTypeInputVal = "";
            }
            setTimeout(() => {
                this.isHandleEditOrDelete = false;
            }, 50);
        },

        // Function called when user select an image
        handleFileSelected() {
            console.log("Component(NewSite)::handleFileSelected() - called");
            if (this.$refs.imageSiteFile.files.length > 0) {
                if (this.$refs.imageSiteFile.files[0].size > 3145728) {
                    // 3 MB max
                    alert(i18n.t("common_fileTooBig", { fileLimit: "3" }));
                    this.newSiteImage = "";
                    this.newSiteImageName = "";
                    this.isImageLoaded = false;
                    this.imageFile = null;
                } else {
                    this.imageFile = this.$refs.imageSiteFile.files[0];
                    this.newSiteImageName = this.imageFile.name;
                    var reader = new FileReader();
                    reader.readAsDataURL(this.imageFile);
                    reader.onload = () => {
                        this.newSiteImage = reader.result;
                        this.isImageLoaded = true;
                    };
                }
            }
        },

        // Function called when user click on the "Remove" button to remove image
        onRemoveImage() {
            this.newSiteImage = "";
            this.newSiteImageName = "";
            this.imageFile = null;
            this.isImageLoaded = false;
            // Force clear of input file to be able to select the same image again
            $("#selectSiteImageInputFile").val(null);
            //  Replaceremoved file path label by common_chooseFile text
            commonVueHelper.resetImageInputLabel();
        },

        // Get array of units with key and tranlated unit
        getSorteUnitsData() {
            // Create array of units (with key and translated type)
            let dataArr = [];
            _.forEach(_siteTypes.list, key => {
                dataArr.push({
                    id: key, // Will be the value
                    text: i18n.t(key) // Will be the text displayed
                });
            });
            // Ascending sort order Select2 data by translated type
            const data = _.orderBy(dataArr, ["text"], ["asc"]);
            return data;
        },

        // Function called to init site types select2
        initSiteTypeSelect2() {
            var self = this;
            // Create sorted array of units (with key and translated type)
            let data = self.getSorteUnitsData();
            // Init unit Select2
            commonVueHelper.destroySelect2($("#newSiteTypeSelect"));
            $("#newSiteTypeSelect")
                .select2({
                    placeholder: i18n.t("site_detailInfoSiteType"),
                    data: data,
                    width: "100%",
                    minimumResultsForSearch: -1
                })
                .val(null)
                .trigger("change")
                // emit event on change.
                .on("change", function() {
                    self.newSiteTypeSelectVal = $(this).val();
                });
        },

        // Function called to update translated site types select2
        updateSiteTypeSelect2(lang) {
            var self = this;
            // Create sorted array of units (with key and translated type)
            let data = self.getSorteUnitsData();
            // Remove old options
            $("#newSiteTypeSelect").empty();
            // Unit translation update
            $("#newSiteTypeSelect")
                .select2({
                    language: lang,
                    placeholder: i18n.t("site_detailInfoSiteType"),
                    data: data
                })
                .val(self.newSiteTypeSelectVal)
                .trigger("change");
        },

        // Function called to init site timezones select2
        initSiteTimezoneSelect2() {
            var self = this;

            // Create sorted array of data
            let dataArr = [];
            _.forEach(this.orderedTimezones, obj => {
                dataArr.push({
                    id: obj.name, // Will be the value
                    text: obj.name + " (GMT" + obj.utcOffsetStr + ")" // Will be the text displayed
                });
            });

            // Init unit Select2
            commonVueHelper.destroySelect2($("#newSiteTimezoneSelect"));
            $("#newSiteTimezoneSelect")
                .select2({
                    placeholder: i18n.t("site_detailInfoSiteTimezone"),
                    width: "100%",
                    language: {
                        noResults: function() {
                            return i18n.t("common_noResultsFound");
                        }
                    },
                    data: dataArr
                })
                .val(null)
                .trigger("change")
                // emit event on change.
                .on("change", function() {
                    self.newSiteTimezoneSelectVal = $(this).val();
                });
        },

        // Function called to init usa states select2
        initUsaStatesSelect2() {
            var self = this;

            // Create array of data
            let dataArr = [];
            _.forEach(this.orderedUsaStates, obj => {
                dataArr.push({
                    id: obj.name, // Will be the value
                    text: obj.name // Will be the text displayed
                });
            });

            // Init unit Select2
            commonVueHelper.destroySelect2($("#usaStateSelect"));
            $("#usaStateSelect")
                .select2({
                    placeholder: i18n.t("site_detailUsaState"),
                    width: "100%",
                    language: {
                        noResults: function() {
                            return i18n.t("common_noResultsFound");
                        }
                    },
                    data: dataArr
                })
                .val(null)
                .trigger("change")
                // emit event on change.
                .on("change", function() {
                    self.newSiteStateInputVal = $(this).val();
                });
        },

        // Function called to init site country select2
        initSiteCountrySelect2() {
            var self = this;

            // Create array of data
            let dataArr = [];
            _.forEach(this.orderedCountries, obj => {
                dataArr.push({
                    id: obj.id, // Will be the value
                    text: obj.name // Will be the text displayed
                });
            });

            // Init unit Select2
            commonVueHelper.destroySelect2($("#newSiteCountrySelect"));
            $("#newSiteCountrySelect")
                .select2({
                    placeholder: i18n.t("site_detailInfoSiteCountry"),
                    width: "100%",
                    language: {
                        noResults: function() {
                            return i18n.t("common_noResultsFound");
                        }
                    },
                    data: dataArr
                })
                .val(null)
                .trigger("change")
                // emit event on change.
                .on("change", function() {
                    self.newSiteCountrySelectVal = $(this).val();
                });
        },

        // Function called when user click on the "Cancel" button
        onCancelButton() {
            console.log("Component(NewSite)::onCancel() - called");
            this.$router.push({ name: "home", params: { fromAction: "cancelButton" } });
        },

        // Function called when user click on the "Create site" button
        onCreateButton() {
            console.log("Component(NewSite)::onCreateButton() - called");
            let siteData = {
                name: this.newSiteNameInputVal,
                description: this.newSiteDescriptionTextAreaVal,
                street: this.newSiteAddressInputVal,
                city: this.newSiteCityInputVal,
                zipCode: this.newSitePostcodeInputVal,
                state: this.newSiteStateInputVal,
                country: this.newSiteCountrySelectVal,
                timezone: this.newSiteTimezoneSelectVal,
                image: this.newSiteImage,
                imageName: this.newSiteImageName,
                createAnother: this.createAnother,
                calibrationMode: this.calibrationMode,
                isWalkwayActivated: this.walkwayMode === "WITH_WALKWAY" ? true : false,
                engineMode: this.newSiteEngineMode,
                BLEGatewaysType: this.newBLEGatewaysType,
            };

            if (this.newSiteTypeSelectVal.isDefault) {
                siteData.siteTypeId = this.newSiteTypeSelectVal.id;
            } else {
                siteData.siteTypeName = this.newSiteTypeSelectVal.name;
            }

            // Add IQM Server configuration
            if (this.IQMServerUrl && this.IQMServerUsername && this.IQMServerPassword && this.IQMFormIsCorrect) {
                siteData["IQMConfiguration"] = {
                    url: this.IQMServerUrl,
                    port: this.IQMServerPort,
                    username: this.IQMServerUsername,
                    password: this.IQMServerPassword
                };
            } else {
                siteData["IQMConfiguration"] = null;
            }

            // Create the new asset in the backend directly as there is no image for the asset
            this.createUserSite(siteData);

        },

        isEmptyForm() {
            // Return true if all control form are empty
            let isEmptyForm = true;
            if (this.newSiteNameInputVal || this.newSiteDescriptionTextAreaVal || this.newSiteAddressInputVal || this.newSiteCityInputVal || this.newSitePostcodeInputVal || this.newSiteStateInputVal || this.newSiteImage || this.newSiteImageName) {
                isEmptyForm = false;
            }
            return isEmptyForm;
        },

        isSuperAdmin() {
            if (this.user) {
                return this.user.isSuperAdmin;
            }
            return false;
        },

        // Function to test if the IQM server is available
        onTestIQMServerButton() {
            const payload = {
                url: this.IQMServerUrl,
                port: this.IQMServerPort,
                username: this.IQMServerUsername,
                password: this.IQMServerPassword
            };
            this.checkIQMServer(payload);
        },

        // Function to reset the form of IQM server configuration
        onResetIQMServerButton() {
            this.IQMServerUrl = null;
            this.IQMServerPort = null;
            this.IQMServerUsername = null;
            this.IQMServerPassword = null;
            this.invalidateIQMServer();
        }
    },
    components: {},
    filters: {},

    beforeRouteLeave(to, from, next) {
        // -- Called when the route that renders this component is about to be navigated away from.
        commonVueHelper.displayConfirmModalOnLeavePage(this, "NewSite", "create", !this.isEmptyForm(), to, from, next);
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
.textLeft {
    text-align: left !important;
}
.imagePreview {
    height: 108px;
    width: 160px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px solid #ebedf2;
}

.site-type {
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
}

.form-site-type {
    position: relative;
}

.icon-down {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    color: #b3b7c0;
}

.list-site-type {
    width: 100%;
    position: absolute;
    top: 100%;
    background: white;
    border: 1px solid #d2d5dc;
    max-height: 200px;
    overflow-y: auto;
    z-index: 89;
    cursor: pointer;
    display: none;
}

.list-site-type ul{
    margin-bottom: 0px;
}
.list-site-type ul li {
    padding: 5px 15px;
}

.list-site-type ul li:hover {
    background: #E1E1E4;
}

.site-type-selected {
    background: #E1E1E4;
}
</style>
